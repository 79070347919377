import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { stack as SideMenu } from 'react-burger-menu';
import Menu from '../Menu';
import Button from '../ButtonTypes';
//import avatar from '../../assets/avatar.png';
import MessageIcon from '../Icons/MessageIcon';
import TurnOffIcon from '../Icons/TurnOffIcon';
import DropDownArrowIcon from '../Icons/DropDownArrowIcon';

// Constants
import { getLogos } from '../../utils/getLogos';
import { colorConfig } from '../../constants/colors';

import { GlobalContext } from '../../providers/GlobalStateProvider';

import './Navbar.scss';
import { formatDate, capitalizeEveryWord } from '../../utils/utils';
import { getAvatarImage } from '../../constants/endpoints';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

// const Colors = {
//   socovesa: '#303446',
//   almagro: '#ffffff',
//   pilares: '#6b6b6b',
//   empresas: '#acacac',
// };

const Navbar = ({
  subsidiary,
  logOutCallback,
  messageCallback,
  notificationList: { numero_mensajes: messageNumber = 0 } = {},
  availableSubsidiaries,
  urlBase,
  isExecutive,
  activePV = false,
  isOwner = false,
  isAdditional = false,
}) => {
  const {
    state: { fullName, token, rut },
    histNav,
    setHistNav,
  } = useContext(GlobalContext);
  const callBreadcrumb = index => {
    if (index === 0) {
      setHistNav(histNav.slice(0, 2));
      histNav[1].action();
    } else {
      setHistNav(histNav.slice(0, index + 1));
      histNav[index].action.forEach(accion => {
        accion();
      });
    }
  };
  const [avatar, setAvatar] = useState(`${getAvatarImage}/${token}}`);
  // const { isOpen, toogleOpen } = useHandleOpenMobileMenu();

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div className="Navbar">
      <div className={`Navbar__desktop Navbar__desktop--${subsidiary}`}>
        <span className={`Navbar__desktop__date Navbar__desktop__date--${subsidiary}`}>
          {formatDate(new Date())}
        </span>
        <div className={`Navbar__desktop__actions Navbar__desktop__actions--${subsidiary}`}>
          <span className="Navbar__desktop__actions__avatarLabel">
            <img className="Navbar__desktop__actions__avatar" src={avatar} alt="avatar" />
            {isExecutive ? (
              <a
                className="Navbar__desktop__actions__avatarLabel"
                href={`${urlBase}/executives/detail/${rut}`}
                style={{ textDecoration: 'none' }}
              >
                {capitalizeEveryWord(fullName)}
              </a>
            ) : (
              <a 
                 href={`${urlBase}/editProfile`}
                className="Navbar__desktop__actions__avatarLabel">
                {capitalizeEveryWord(fullName)}
              </a>
            )}
          </span>
          {availableSubsidiaries && availableSubsidiaries.messages && (
            <Button
              variant="text"
              onClick={messageCallback}
              className={`Navbar__desktop__actions__notificationButton ${messageNumber > 0 &&
                `Navbar__desktop__actions__notificationButton--hasNotifications--${subsidiary}`}`}
            >
              <MessageIcon
                className={`Navbar__desktop__actions__notifications Navbar__desktop__actions__logoutButton--${subsidiary}`}
                color="currentColor"
              />
            </Button>
          )}

          <Button
            variant="text"
            onClick={() => {
              window.gtag('event', 'CERRAR SESIÓN', {
                event_category: 'authentication',
                archivo: '',
                proyecto: 'GENERAL',
                filial: `${subsidiary}`.toUpperCase(),
                id_cliente: rut,
                propiedad: '',
                etapa: ''
              });
              logOutCallback();
            }}
          >
            <TurnOffIcon
              className={`Navbar__desktop__actions__logoutButton Navbar__desktop__actions__logoutButton--${subsidiary}`}
              color="currentColor"
            />
          </Button>

        </div>
      </div>
      <div className={`Navbar__mobile Navbar__mobile--${subsidiary}`}>
        <div className="Navbar__mobile__menugroup">
          <SideMenu width="100%" isOpen={isOpen} onOpen={handleOpen} onClose={handleClose}>
            <div className="Navbar__mobile__innerMenu">
              <div
                className={`Navbar__mobile__innerMenu__header Navbar__mobile__innerMenu__header--${subsidiary}`}
              >
                <img
                  alt="logo"
                  className="Navbar__mobile__innerMenu__header__logo"
                  {...getLogos(subsidiary, true)}
                />
                <div className="Navbar__mobile__innerMenu__header__portraid">
                  <img
                    className="Navbar__mobile__innerMenu__header__portraid__avatar"
                    src={avatar}
                    alt="avatar"
                  />
                  <span className="Navbar__mobile__innerMenu__header__portraid__name">
                    {capitalizeEveryWord(fullName)}
                  </span>
                  <DropDownArrowIcon
                    className="Navbar__mobile__innerMenu__header__portraid__drowDownButton"
                    color={colorConfig[subsidiary].primary}
                  />
                </div>
              </div>
              <Menu
                className="Navbar__mobile__innerMenu__Menu"
                subsidiary={subsidiary}
                onClick={() => {
                  setIsOpen(false);
                }}
                activePV={activePV}
                isOwner={isOwner}
                isAdditional={isAdditional}
              />
            </div>
          </SideMenu>
          <img alt="logo" className="Navbar__mobile__logo" {...getLogos(subsidiary, true)} />
        </div>
        <div className="Navbar__mobile__icongroup">
          {availableSubsidiaries && availableSubsidiaries.messages && (
            <Button variant="text" onClick={messageCallback}>
              <MessageIcon
                className={`Navbar__mobile__notifications Navbar__mobile__notifications--${subsidiary}`}
                color="currentColor"
              />
            </Button>
          )}
          <Button variant="text" onClick={logOutCallback}>
            <TurnOffIcon
              className={`Navbar__desktop__actions__logoutButton Navbar__desktop__actions__logoutButton--${subsidiary}`}
              color="currentColor"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  subsidiary: PropTypes.string,
  logOutCallback: PropTypes.func,
  messageCallback: PropTypes.func,
  notificationList: PropTypes.object,
  isExecutive: PropTypes.bool,
};

export default Navbar;
