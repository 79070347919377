import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Button from '../ButtonTypes';
import FileIcon from '../Icons/FileIcon';
import ClearIcon from '../Icons/ClearIcon';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import PlayIcon from '../Icons/PlayIcon';
import GalleryIcon from '../Icons/GalleryIcon';
import VideoIcon from '../Icons/VideoIcon/Video';
import ImageIcon from '../Icons/ImageIcon/ImageIcon';

import { empresas } from '../../constants/filialesNames';
import { URL_FILES_PROD } from '../../constants/urlSocovesa';
import { formatDate, openDocOnNewTab, parseDocumentName, openPromiseOnNewTab } from '../../utils/utils';

import './DocumentItem.scss';
import { useHasPermission } from '../../customHooks/usePermissions';

const DeleteButton = ({ onClick, className }) => (
  <Button onClick={onClick} variant="text" className={className}>
    <span className="DeleteButton">
      <ClearIcon color="currentColor" className="DeleteButton__container" />
      <span className="DeleteButton__label">eliminar</span>
    </span>
  </Button>
);


const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: "1rem", // Tamaño normal
    padding: "8px 16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      padding: "6px 12px",
    },
  },
}));


DeleteButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

const DocumentItem = ({ subsidiary, lastModified, name, label, deleteCallback, onCheck, checked = false, link, buttonDel = false, onlyList = false, extension, proyecto = 'GENERAL', tipoDocumento = 'SIN CLASIFICACION' }) => {

  const permissions = useHasPermission(sessionStorage.getItem('filial'), null); //TODO: check if we need also the state the unit is in
  
  const getCurrentRut = () => JSON.parse(sessionStorage.getItem('username'));
  
  const [open, setOpen] = useState(false);
  const [fileSrc, setFileSrc] = useState("");

  const handleOpen = (fileUrl) => {
    setFileSrc(fileUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFileSrc(""); 
  };

  const classes = useStyles();

  
  return (
    <Fragment>
      <div className={`DocumentItem DocumentItem--${subsidiary}`}>
        <div className={`DocumentItem__simbol DocumentItem__simbol--${subsidiary}`}>
          {
            extension === 'video' ? (
              <VideoIcon className="DocumentItem__simbol__icon" color="currentColor" />
            ) : extension === 'imagen' ? (
              <GalleryIcon className="DocumentItem__simbol__icon" color="currentColor" />
            ) : (
              <FileIcon className="DocumentItem__simbol__icon" color="currentColor" />
            )
          }
        </div>
        <div className={`DocumentItem__container DocumentItem__container--${subsidiary}`}>
          
          <div className="DocumentItem__nameDateItem">
            <span className="DocumentItem__nameDateItem__date">
              documento publicado {formatDate(lastModified, 'dd/MM/yyyy')}
            </span>
            <span className="DocumentItem__nameDateItem__docname">{name}</span>
            <span className="DocumentItem__nameDateItem__lastModifiedDate">
              {formatDate(lastModified, 'dd/MM/yyyy')}
            </span>
          </div>
         
          <div className="DocumentItem__container__buttons">
            {subsidiary === empresas && (buttonDel  || !onlyList) && (
              <DeleteButton
                className="DocumentItem__container__DeleteButton"
                onClick={deleteCallback}
              />
            )}

            {subsidiary !== empresas && (
              <Fragment>
                
                <span className="DocumentItem__container__buttons__lastModifiedDate">
                  {formatDate(lastModified, 'dd/MM/yyyy')}
                </span>
                {
                  extension === 'video' ? (
                    <Button
                      variant="text"
                      onClick={() => {
                        window.gtag('event', 'VISUALIZACIÓN VIDEO', {
                          event_category: 'show-video',
                          archivo: `${tipoDocumento}/${label}`.toUpperCase(),
                          tipoDocumento: `${tipoDocumento}`.toUpperCase(),
                          proyecto: `${proyecto}`.toUpperCase(),
                          filial: `${subsidiary}`.toUpperCase(),
                          id_cliente: `${getCurrentRut()}`,
                          propiedad: '',
                          etapa: ''
                        });

                        handleOpen(link);
                      }}
                    >
                      <PlayIcon
                        className={`DocumentItem__container__icon DocumentItem__container__icon--${subsidiary}`}
                        color="black"
                      />
                    </Button>
                  ) : extension === 'imagen' ? (
                    <Button
                      variant="text"
                      onClick={() => {
                        window.gtag('event', 'VISUALIZACIÓN IMAGEN', {
                          event_category: 'show-image',
                          archivo: `${tipoDocumento}/${label}`.toUpperCase(),
                          tipoDocumento: `${tipoDocumento}`.toUpperCase(),
                          proyecto: `${proyecto}`.toUpperCase(),
                          filial: `${subsidiary}`.toUpperCase(),
                          id_cliente: `${getCurrentRut()}`,
                          propiedad: '',
                          etapa: ''
                        });

                        handleOpen(link); 
                      }}
                    >
                      <ImageIcon
                        className={`DocumentItem__container__icon DocumentItem__container__icon--${subsidiary}`}
                        color="currentColor"
                      />
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      onClick={() => {
                        window.gtag('event', 'DESCARGA DOCUMENTO', {
                          event_category: 'download files',
                          archivo: `${tipoDocumento}/${label}`.toUpperCase(),
                          tipoDocumento: `${tipoDocumento}`.toUpperCase(),
                          proyecto: `${proyecto}`.toUpperCase(),
                          filial: `${subsidiary}`.toUpperCase(),
                          id_cliente: `${getCurrentRut()}`,
                          propiedad: '',
                          etapa: ''
                        });

                        window.open(link, '_blank');
                      }}
                    >
                      <ArrowDownIcon
                        className={`DocumentItem__container__icon DocumentItem__container__icon--${subsidiary}`}
                        color="currentColor"
                      />
                    </Button>
                  )
                }
              </Fragment>
            )}
          </div>
        </div>
      </div>
      
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          {extension === 'video' ? (
            <video
              width="100%"
              autoPlay
              controls
              onContextMenu={(e) => e.preventDefault()}
              disablePictureInPicture
              controlsList="nodownload"
            >
              <source src={fileSrc} type="video/mp4" />
              Tu navegador no soporta la reproducción de videos.
            </video>
          ) : extension === 'imagen' ? (
            <img
              src={fileSrc}
              alt="File Preview"
              style={{ width: '100%', height: 'auto' }}
            />
          ) : (
            <p>No se puede previsualizar este tipo de archivo.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" className={classes.button}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

DocumentItem.propTypes = {
  subsidiary: PropTypes.string,
  lastModified: PropTypes.string,
  name: PropTypes.string,
  deleteCallback: PropTypes.func,
  tipoDocumento: PropTypes.string,
};

export default DocumentItem;
