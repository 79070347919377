import axios from 'axios';

import {
  getDataProfile as getDataProfileClient,
  updateDataProfile as updateProfile,
  updateDataAdditional as updateAdditional,
  getDataAdditional as getDataProfileAdditional

} from '../constants/endpoints';


export const getProfileData = (rut, successCallback, errorCallback) => {
  
  const endpoint = `${getDataProfileClient}/${rut}`
  return axios
    .get(endpoint)
    .then(successCallback)
    .catch(errorCallback);
};


export const getProfileDataAdditional = (rut, successCallback, errorCallback) => {

  const endpoint = `${getDataProfileAdditional}/${rut}`
  return axios
    .get(endpoint)
    .then(successCallback)
    .catch(errorCallback)
}


    
export const updateProfileData = async (rut, nombres, apellidos, telefonoCelular, eMailParticular) => {
  try {
    const response = await axios.post(`${updateProfile}`, { 
      id_cliente: rut, 
      nombres, 
      apellidos, 
      telefonoCelular,
      eMailParticular
    });
    return response.data; 
  } catch (error) {

    console.error('Error al actualizar perfil propietario:', error);
    throw error; 
  }
}



export const updateAdditionalProfileData = async (rut, solicitantenombre, solicitanteemail, solicitantephone) => {
  try {
    const response = await axios.post(`${updateAdditional}`, { 
      solicitantenombre, 
      solicitanteemail, 
      solicitantephone, 
      solicitanterut: rut 
    });
    console.log(response);
    return response.data; 
  } catch (error) {

    console.error('Error al actualizar perfil adicional:', error);
    throw error; 
  }
}

