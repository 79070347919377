import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../ButtonTypes';
import FileIcon from '../Icons/FileIcon';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import { formatDate } from '../../utils/utils';

import './DocumentItem.scss';
import { useRequerimientosPorSolicitud } from '../../customHooks/postventa';

const DocumentDetailPV = ({ subsidiary, requirement, proyecto }) => {
  const { documentos } = useRequerimientosPorSolicitud(requirement.solicitudId);
  const name = `SOLICITUD ${requirement.nroSolicitud}`;
  const date = requirement.fechaIngreso;
  const hasIdOT = documentos.some(doc => doc.IdOT);
  const hasIdOV = documentos.some(doc => doc.IdOV);

  const getCurrentRut = () => JSON.parse(sessionStorage.getItem('username'));

  return (
    <div className={`DocumentItem DocumentItem--${subsidiary}`}>
      <div className={`DocumentItem__simbol DocumentItem__simbol--${subsidiary}`}>
        <FileIcon className="DocumentItem__simbol__icon" color="currentColor" />
      </div>
      <div className={`DocumentItem__container DocumentItem__container--${subsidiary}`}>
        <div className="DocumentItem__nameDateItem">
          <span className="DocumentItem__nameDateItem__date">Fecha de solicitud: {date}</span>
          <span className="DocumentItem__nameDateItem__docname">
            {name} - {requirement.estadoSolicitud}
          </span>
          <span className="DocumentItem__nameDateItem__lastModifiedDate">
            {formatDate(new Date(), 'dd/MM/yyyy')}
          </span>
        </div>
        <div className="DocumentItem__container__buttons">
          <Fragment>
          <Button
              title="Acta de Solicitud"
              variant="text"
              onClick={() => {
                const documento = documentos.find(doc => doc.detalle === 'Detalle de la solicitud');
                if (documento) {
                  
                  window.gtag('event', 'DESCARGA ACTA SOLICITUD POSTVENTA', {
                    event_category: 'download files',
                    archivo: name,
                    proyecto: proyecto,
                    filial: `${subsidiary}`.toUpperCase(),
                    id_cliente: `${getCurrentRut()}`,
                    propiedad: '',
                    etapa: ''
                  });

                 
                  window.open(documento.url, '_blank');
                }
              }}
            >
              <ArrowDownIcon
                className={`DocumentItem__container__icon DocumentItem__container__icon--${subsidiary}`}
                color="currentColor"
              />
            </Button>

            {hasIdOT && (
              <Button
              title="Orden de Trabajo"
              variant="text"
              onClick={() => {
                const documento = documentos.find(doc => doc.IdOT);
                if (documento) {
                  window.gtag('event', 'DESCARGA ORDEN DE TRABAJO SOLICITUD POSTVENTA', {
                    event_category: 'download files',
                    archivo: name,
                    filial: `${subsidiary}`.toUpperCase(),
                    proyecto: proyecto,
                    id_cliente: `${getCurrentRut()}`,
                    propiedad: '',
                    etapa: ''
                  });
            
                  window.open(documento.url, '_blank');
                }
              }}
            >
              <ArrowDownIcon
                className={`DocumentItem__container__icon DocumentItem__container__icon--${subsidiary}`}
                color="#40CFFF"
              />
            </Button>            
            )}
            {hasIdOV && (
              <Button
                title="Orden de Visita"
                variant="text"
                onClick={() => {
                  const documento = documentos.find(doc => doc.IdOV);
                  if (documento) {
                    window.gtag('event', 'DESCARGA DOCUMENTO ORDEN DE VISITA POSTVENTA', {
                      event_category: 'download files',
                      archivo: name,
                      filial: `${subsidiary}`.toUpperCase(),
                      proyecto: proyecto,
                      id_cliente: `${getCurrentRut()}`,
                      propiedad: '',
                      etapa: ''
                    });
              
                    window.open(documento.url, '_blank');
                  }
                }}
              >
                <ArrowDownIcon
                  className={`DocumentItem__container__icon DocumentItem__container__icon--${subsidiary}`}
                  color="green"
                />
              </Button>
            )}
          </Fragment>
        </div>
      </div>
    </div>
  );
};

DocumentDetailPV.propTypes = {
  subsidiary: PropTypes.string,
  date: PropTypes.string,
  name: PropTypes.string,
  requirementId: PropTypes.string,
  proyecto: PropTypes.string
};

export default DocumentDetailPV;
