import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, MenuItem, Select, DialogActions, Button as MuiButton } from '@material-ui/core';

// componentes and scss
import './ClientDetail.scss';
import DownArrowIconComponent from '../../components/Icons/DownArrow';
import { colorConfig } from '../../constants/colors';
import DocumentList from '../../components/DocumentList/DocumentList';
import MilestonCalendar from '../../components/MilestonesCalendar/MilestonCalendar';
import Button from '../../components/ButtonTypes/ButtonTypes';

// services
import { useSignedDocumentsByUnit } from '../../customHooks/fetchSignedDocumentsByProperty ';
import { useMinutesByUnit } from '../../customHooks/fetchMinutessByProperty';
import DocumentListExec from '../../components/DocumentList/DocumentListExec';
import { useRequerimientosPorSolicitud, useSolicitudPorUnidad } from '../../customHooks/postventa';
import { updateStatusByBusinessId } from '../../services/postventaService';
import RequirementView from '../../components/ProjectListPostVenta/RequirementView';
import RequestsPostVenta from '../../components/ProjectListPostVenta/RequestsPostVenta';
import FilterRequests from '../../components/ProjectListPostVenta/FilterRequests';
import Dialog from '../../components/Dialog';
import { getStatusByBusinessId } from '../../services/postventaService';

const ClientProperties = ({ properties = [], projectId, clientId, subsidiary }) => {
  const [finalProperties, setFinalProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedPropertyId, setSelectedPropertyId] = useState('');
  const { signedDocuments, setSignedDocuments } = useSignedDocumentsByUnit(
    clientId,
    selectedProperty,
    projectId,
  );
  const { minutes, setMinutes } = useMinutesByUnit(selectedProperty);
  const { solicitudes, setSolicitudes } = useSolicitudPorUnidad(selectedPropertyId);


  const businessIds = useMemo(() => {
    return finalProperties.reduce((acc, property) => {
      acc[property.id_Propiedad] = property.idNegocio;
      return acc;
    }, {});
  }, [finalProperties]);

  const [statusCode, setStatusCode] = useState([])
  const [filteredRequests, setFilteredRequests] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentRequirement, setCurrentRequirement] = useState({});
  const [selectedRequest, setSelectedRequest] = useState('');
  const { requerimientos, documentos } = useRequerimientosPorSolicitud(
    selectedRequest,
  );
  const [activeSolicitud, setActiveSolicitud] = useState();
  const [nroSolicitud, setNroSolicitud] = useState();
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [propertyStatuses, setPropertyStatuses] = useState({});

  const statusColors = {
    'En promesa': '#FFC107',
    'En escritura': '#00BCD4',
    'En entrega': '#FFEB3B',
    'En postventa': '#D83535',
  };

  const statusCodeNames = {
    100: 'En promesa',
    200: 'En escritura',
    300: 'En entrega',
    400: 'En postventa',
  }

  const selectProperty = property => {
    setSelectedProperty(!!property ? property.id_Propiedad : '')
    setSelectedPropertyId(!!property ? property.id_PropiedadPV : '')
  };

  useEffect(() => {
    setFinalProperties(
      properties.map(property => {
        property.expanded = false;
        return property;
      }),
    );
  }, [properties]);

  useEffect(() => {
    if (finalProperties.length === 1) selectProperty(finalProperties[0]);
  }, [finalProperties]);

  const addPropertyStatus = (businessId, propertyId) => {
    if (!businessId || !propertyId || propertyStatuses[propertyId]) return;
  
    getStatusByBusinessId(
      businessId,
      clientId,
      ({ data }) => {
        setPropertyStatuses(prevStatuses => ({
          ...prevStatuses,
          [propertyId]: statusCodeNames[data[0]?.idEstadoNegocio] || 'Desconocido',
        }));
      },
      err => {
        console.error(`Error al obtener el estado para businessId ${businessId}:`, err);
      }
    );
  };

  useEffect(() => {
    finalProperties.forEach(property => {
      const businessId = businessIds[property.id_Propiedad];
      if (businessId) {
        addPropertyStatus(businessId, property.id_Propiedad);
      }
    });
  }, [finalProperties, businessIds]);

  const changeExpandedProperty = propertyId => {
    setSelectedProperty(propertyId);
    setPropertyStatuses((prevStatuses) => ({
      ...prevStatuses,
      [selectedProperty]: statusCodeNames[statusCode],
    }));
    setFinalProperties(
      finalProperties.map(property => {
        if (property.id_Propiedad === propertyId) {
          property.expanded = !property.expanded;
          if (property.expanded) selectProperty(property);
          else selectProperty('');
        } else property.expanded = false;
        return property;
      }),
    );
  };

  const handleStatusButtonClick = (propertyId) => {
    if (isAdmin) {
      setSelectedProperty(propertyId);
      setStatusDialogOpen(true);
      setSelectedStatus('');
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value); 
  };

  const handleConfirmStatusChange = () => {
    setPropertyStatuses((prevStatuses) => ({
      ...prevStatuses,
      [selectedProperty]: statusCodeNames[selectedStatus], 
    }));
    if (selectedStatus === '') return;
    updateStatusByBusinessId(
      businessIds[selectedProperty],
      clientId,
      selectedStatus,
      () => {
        console.log('Estado actualizado para propiedad con id de negocio ', businessIds[selectedProperty]);
        setStatusCode(selectedStatus);
      },
      err => {
        console.error(err);
      },
    )

    setStatusDialogOpen(false);
  };

  const handleCancelStatusChange = () => {
    setSelectedStatus('');
    setStatusDialogOpen(false);
  };

  const accordionContent = property => (
    <div className="ClientDocuments">
      <h5>DOCUMENTOS FIRMADOS</h5>
      {signedDocuments && signedDocuments.length > 0 ? (
        <>
          <DocumentList subsidiary={subsidiary} fileList={signedDocuments} />
        </>
      ) : (
        <i>El negocio no tiene documentos firmados.</i>
      )}
      <h5>ACTAS DE ENTREGA</h5>
      {minutes && minutes.length > 0 ? (
        <>
          {/* <DocumentListExec subsidiary={subsidiary} fileList={minutes} onlyList /> */}
        </>
      ) : (
        <i>El cliente no tiene actas de entrega</i>
      )}
      {
        !!solicitudes && solicitudes.length > 0 && (
          <>
            <h5>SOLICITUDES</h5>
            <div className='filter'>
              <FilterRequests requests={solicitudes} setRequests={setFilteredRequests} />
            </div>
            <RequestsPostVenta
              requests={filteredRequests}
              subsidiary={subsidiary}
              setDialogOpen={setDialogOpen}
              setSelectedRequirement={setCurrentRequirement}
              initialRequestQuantity={10}
              requerimientos={requerimientos}
              documentos={documentos}
              setSelectedRequest={setSelectedRequest}
              setActiveSolicitud={setActiveSolicitud}
              setNroSolicitud={setNroSolicitud}
            />
          </>
        )}
      <h5>CALENDARIO DE HITOS</h5>
      <MilestonCalendar
        idProject={projectId}
        clientId={clientId}
        propertyId={property.id_Propiedad}
        propertyNumber={property.numero_Departamento}
      />
    </div>
  );

  return (
    <div className="ClientProperties">
      <Dialog
        open={dialogOpen}
        className="dialog-view"
        onClose={() => setDialogOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        scroll="paper"
        classes={{ paper: 'dialog-view' }}
      >
        <RequirementView
          requirement={currentRequirement}
          handleClose={() => setDialogOpen(false)}
          subsidiary={subsidiary}
        />
      </Dialog>

      <Dialog
        open={statusDialogOpen}
        onClose={handleCancelStatusChange}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        classes={{ paper: 'dialog-view' }}
      >
        <div className='RequirementView__header'>
          <div className="RequirementView__header__title">
            <span className="RequirementView__header__title__req">Estado de negocio</span>
            <span className="RequirementView__header__title__mid"> </span>
            <span className="RequirementView__header__title__est">
              <span className="RequirementView__header__title__est__icon">
                {finalProperties.find(prop => prop.id_Propiedad === selectedPropertyId)?.tipo_Unidad || ''} {finalProperties.find(prop => prop.id_Propiedad === selectedPropertyId)?.numero_Departamento || ''}
              </span>
            </span>
          </div>
        </div>
        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h4 style={{ marginBlockEnd: '0.33em' }}>Estado actual: </h4> 
            <span style={{textTransform: 'uppercase', marginBlockEnd: '1.33em', fontWeight: 'bolder', color: statusColors[propertyStatuses[selectedPropertyId]] || 'grey'}}>
              {propertyStatuses[selectedPropertyId] || 'No seleccionado aún'}
            </span>
            <Select value={selectedStatus} onChange={handleStatusChange} fullWidth style={{ width: '30%',backgroundColor: 'whitesmoke'}} displayEmpty>
              <MenuItem value="" disabled>
                Cambiar estado de negocio
              </MenuItem>
              <MenuItem value="100" style={{ color: 'black', backgroundColor: statusColors['En promesa'] }}>En promesa</MenuItem>
              <MenuItem value="200" style={{ color: 'white', backgroundColor: statusColors['En escritura'] }}>En escritura</MenuItem>
              <MenuItem value="300" style={{ color: 'black', backgroundColor: statusColors['En entrega'] }}>En entrega</MenuItem>
              <MenuItem value="400" style={{ color: 'white', backgroundColor: statusColors['En postventa'] }}>En postventa</MenuItem>
            </Select>
        </div>
        <DialogActions style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <Button onClick={handleCancelStatusChange}>
            Cancelar
          </Button>
          <Button onClick={handleConfirmStatusChange}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {finalProperties.length >= 1 ? (
        <>
          {finalProperties.map((property, index) => {
            return (
              <ExpansionPanel
                key={index}
                expanded={property.expanded}
                onChange={() => {
                  changeExpandedProperty(property.id_Propiedad);
                }}
                className="ClientProperty"
              >
                <ExpansionPanelSummary
                  expandIcon={<DownArrowIconComponent color={colorConfig[subsidiary].icons} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ border: '1px solid', borderRadius: '5px' }}
                >
                  <h4>{`${property.tipo_Unidad} ${property.numero_Departamento}`}</h4>
                  {property.expanded && (
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <span style={{marginRight: '10px', fontWeight: 'bold'}}>Estado: </span>
                      <Button
                        onClick={(event) => {
                        event.stopPropagation();
                        handleStatusButtonClick(property.id_Propiedad);
                      }}
                      style={{
                        color: 'white',
                        backgroundColor: statusColors[propertyStatuses[property.id_Propiedad] || 'Seleccione Estado'],
                      }}
                    >
                      {propertyStatuses[property.id_Propiedad] || 'Seleccione Estado'}
                    </Button>
                    </div>
                  )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ borderRadius: '5px' }}>
                  {selectedProperty === property.id_Propiedad && accordionContent(selectedProperty)}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </>
      ) : (
        finalProperties.length === 0 && (
          <>
            <h4>No hay propiedades</h4>
          </>
        )
      )}
    </div>
  );
};

ClientProperties.propTypes = {
  properties: PropTypes.array,
  projectId: PropTypes.string,
  clientId: PropTypes.string,
  subsidiary: PropTypes.string,
};

export default ClientProperties;
