import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';

import './ClientDetail.scss';
import DownArrowIconComponent from '../../components/Icons/DownArrow';
import { colorConfig } from '../../constants/colors';
import { usePropertiesByClientAndProject } from '../../customHooks/fetchPropertiesByClientAndProject';
import ClientProperties from './ClientProperties';
import { useClientDocuments } from '../../customHooks/documents';
import { EnterpriseContext } from '../../providers/EnterpriseProvider';
import { listClientDocuments, uploadFileClient } from '../../services/documents';
import { deleteClientFile } from '../../services/projectsService';
import DocumentListExec from '../../components/DocumentList/DocumentListExec';

const updateDocsList = (projectId, clientId, updateCallback) => {
  if (projectId && clientId) {
    listClientDocuments(
      projectId,
      undefined,
      clientId,
      undefined,
      ({ data }) => {
        updateCallback(data);
      },
      err => {
        console.error(err);
      },
    );
  }
};

const ClientProjects = ({ projects = [], clientId, subsidiary }) => {
  const [finalProjects, setFinalProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const { properties, setProperties } = usePropertiesByClientAndProject(clientId, selectedProject);
  const {
    clientDocuments,
    setClientDocuments,
    projectDocuments,
    setProjectDocuments,
  } = useClientDocuments(selectedProject, clientId);
  const { state: { executiveRut } = {} } = useContext(EnterpriseContext);

  const useHandleFileUpload = useCallback(
    (file, label) => {
      uploadFileClient(selectedProject, executiveRut, clientId, label, file, () => {
        updateDocsList(selectedProject, clientId, data => {
          setClientDocuments(data.docClientes);
          setProjectDocuments(data.docProyectos);
        });
      });
    },
    [selectedProject, clientId],
  );

  const deleteFileCallback = label => {
    deleteClientFile(
      undefined,
      undefined,
      undefined,
      undefined,
      label,
      () => {
        updateDocsList(selectedProject, clientId, data => {
          setClientDocuments(data.docClientes);
          setProjectDocuments(data.docProyectos);
        });
      },
      err => {
        console.error(err);
      },
    );
  };

  useEffect(() => {
    setFinalProjects(
      projects.map(project => {
        project.expanded = false;
        return project;
      }),
    );
  }, [projects]);

  useEffect(() => {
    if (finalProjects.length === 1) setSelectedProject(finalProjects[0].id_Proyecto);
  }, [finalProjects]);

  const changeExpandedProject = projectId => {
    if (selectedProject !== projectId) setProperties([]);
    const newFinalProjects = finalProjects.map(project => {
      if (project.id_Proyecto === projectId) {
        return { ...project, expanded: !project.expanded };
      }
      return { ...project, expanded: false };
    });
    setFinalProjects(newFinalProjects);
    setSelectedProject(projectId);
  };

  const accordionContent = () => (
    <div style={{ width: '100%' }}>
      <ClientProperties
        properties={properties}
        clientId={clientId}
        subsidiary={subsidiary}
        projectId={selectedProject}
      />
      {clientDocuments.length > 0 ? <h5>DOCUMENTOS DEL EJECUTIVO</h5> : null}
      {/* <DocumentListExec
        subsidiary={subsidiary}
        fileList={clientDocuments}
        uploadCallback={useHandleFileUpload}
        deleteCallback={deleteFileCallback}
        buttonDel
      /> */}
      {projectDocuments.length > 0 ? <h5>DOCUMENTOS DEL PROYECTO</h5> : null}
      <DocumentListExec
        subsidiary={subsidiary}
        fileList={projectDocuments}
        uploadCallback={useHandleFileUpload}
        deleteCallback={deleteFileCallback}
      />
    </div>
  );

  return (
    <div className="ClientProjects">
      <h5>DOCUMENTOS</h5>
      {finalProjects.length > 1 ? (
        <>
          {finalProjects.map((project, index) => {
            return (
              <ExpansionPanel
                key={index}
                expanded
                onChange={() => {
                  changeExpandedProject(project.id_Proyecto);
                }}
                className="ClientProject"
              >
                <ExpansionPanelSummary
                  expandIcon={<DownArrowIconComponent color={colorConfig[subsidiary].icons} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ border: `1px solid`, borderRadius: '5px' }}
                >
                  <h4>{`Proyecto: ${project.nombre_Proyecto}`}</h4>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ borderRadius: '5px' }}>
                  {selectedProject === project.id_Proyecto && accordionContent()}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </>
      ) : (
        finalProjects.length === 1 && (
          <>
            <h4>{`Proyecto: ${finalProjects[0].nombre_Proyecto}`}</h4>
            {accordionContent()}
          </>
        )
      )}
    </div>
  );
};

ClientProjects.propTypes = {
  projects: PropTypes.array,
  clientId: PropTypes.string,
  subsidiary: PropTypes.string,
};

export default ClientProjects;
