
import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIslogged } from '../../customHooks/useIslogged';
import DocumentListExec from '../../components/DocumentList/DocumentListExec';
import { getDocumentsAdditional } from '../../services/documents';
import { colorConfig } from '../../constants/colors';


const ProyectDocuments = ({
    history,
    match: {
      params: { subsidiary },
    },
    propiedadesPV,
  }) => {


    if (!useIslogged()) {
      history.push(`/${subsidiary}`);
    }

    const [projectDocuments, setProjectDocuments] = useState([]);
  
  
    // Verificamos si propiedadesPV está disponible antes de intentar obtener documentos
    const getDocuments = () => {
      if (!propiedadesPV || !propiedadesPV.owners || propiedadesPV === 0) return; // Evitamos ejecutar el código si propiedadesPV es 0, null o undefined
  
      const idOwners = propiedadesPV.owners.flatMap(owner =>
        owner.filiales.flatMap(filial =>
          filial.proyectos.flatMap(proyecto =>
            proyecto.unidades.map(unidad => ({
              idOwner: owner.idOwner,
              etapa: unidad.etapa,
            }))
          )
        )
      ) || [];
  
      getDocumentsAdditional(
        'VER EN ADICIONALES',
        idOwners,
        (response) => {
          setProjectDocuments(response.data);
        },
        (error) => {
          console.error(error);
        }
      );
    };
  
    useEffect(() => {
      if (propiedadesPV) {
        getDocuments();
      }
    }, [propiedadesPV]); // Solo llamamos a getDocuments si propiedadesPV cambia y es válido
  
    return (
      <>
        {propiedadesPV && propiedadesPV.owners && propiedadesPV.owners.length > 0 ? (
          propiedadesPV.owners.map(ownerProperties => {
            const ownerDocuments = projectDocuments.find(doc => doc.idOwner === ownerProperties.idOwner);
  
            return (
              <div style={{ paddingBottom: '0.30rem', paddingTop: '3rem' }} key={ownerProperties.idOwner}>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    color: colorConfig[subsidiary].headerTexts,
                  }}
                >
                  {`${ownerProperties.filiales[0].filial}: Propietario ${ownerProperties.ownerFullName}`}
                </Typography>
  
                {/* Verificamos si hay documentos para este propietario */}
                <div className="Home__body__section__container">
                  {ownerDocuments && ownerDocuments.result.length > 0 ? (
                    <div className="Home__body__section__container__list">
                      <div
                        className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
                      >
                        <DocumentListExec
                          fileList={ownerDocuments.result}
                          subsidiary={subsidiary}
                          proyecto="SIN PROYECTO ASOCIADO"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="Home__body__section__container__list">
                      <div
                        className={`Home__body__section__container__list__item Home__body__section__container__list__item--${subsidiary}`}
                      >
                        <p>No hay documentos para visualizar.</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <Typography variant="h6">Cargando documentos...</Typography>
        )}
      </>
    );
  };


ProyectDocuments.propTypes = {
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
  additional: PropTypes.bool,
  admin: PropTypes.bool,
};

export default ProyectDocuments;
