/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, Fragment, useCallback } from 'react';
import Proptypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '../TextFields';
import SelectInput from '../SelectInput';
import Button from '../ButtonTypes';
import DeleteButton from '../DeleteButton';
import Dialog from '../Dialog';
import MultipleUploadItem from './MultipleUploadItem';
import FileUploadInput from '../FileUploadInput';
import { openDocOnNewTab } from '../../utils/utils';
import { URL_FILE_SOCOVESA_PROD } from '../../constants/urlSocovesa';
import { useHasPermission } from '../../customHooks/usePermissions';
import { getActiveClientsByProject, getUnitTypesByProject, getClientsByUnitType, listDocumentTypes, listDepartmentData } from '../../services/projectsService';
import './MultipleUpload.scss';
import { Box, Card, CardContent, IconButton, List, ListItem, MenuItem, Popover, Select } from '@material-ui/core';
import { Info } from '@material-ui/icons';



const transformConfiguration = (config, currentConfiguration) => {
  if (!config?.length) return null;
  const doc = config[0];

  const previousVisualizar = currentConfiguration?.visualizar || {};

  return {
    cargar: [
      { value: 'POR CLIENTE', label: 'POR CLIENTE', dialog: !!doc?.cargaporunidad },
      { value: 'A TODO EL PROYECTO', label: 'A TODO EL PROYECTO', dialog: !!doc?.cargaporproyecto },
      { value: 'POR TIPO DE UNIDAD', label: 'POR TIPO DE UNIDAD', dialog: !!doc?.cargaportipounidad },
    ],
    visualizar: { 
      'EN PROPIETARIOS': previousVisualizar['EN PROPIETARIOS'] ?? !!doc?.verporclientesunidades,
      'EN ADICIONALES': previousVisualizar['EN ADICIONALES'] ?? !!doc?.verporadicionales,
      'EN COMUNIDADES': previousVisualizar['EN COMUNIDADES'] ?? !!doc?.verporcomunidades   
    },
    multiplesDocumentos: !!doc?.multiplesDocumentos,
  };
};


const validarArchivo = (files, listDocuments)  => {
  
  if (!files.length) {
      throw new Error("No se ha seleccionado ningún archivo.");
  }

  const fileName = files[0].name;
  const existe = listDocuments.some(doc => doc.name === fileName);

  return existe;

}


const dialogOption = ['POR TIPO DE UNIDAD', 'POR CLIENTE'];

const useGetUnitTypes = projectId => {
  const [unitTypes, setUnitTypes] = useState([]);
  const [allSelectedUnitTypes, setAllSelectedUnitTypes] = useState(false);

  useEffect(() => {
    getUnitTypesByProject(
      projectId,
      ({ data }) => {
        setUnitTypes(data.data.map(unitType => ({ ...unitType, checked: false, projectId })));
      },
      err => {
        console.error(err);
      },
    );
  }, [projectId]);


  const handleUnitTypeCheck = unitTypeId => {
    setUnitTypes(prevUnitTypes =>
      prevUnitTypes.map(unitType =>
        (unitType.idModelo === unitTypeId ? { ...unitType, checked: !unitType.checked } : unitType),
      ),
    );
  };

  const selectAllUnitTypes = () => {
    const updatedFlag = !allSelectedUnitTypes;
    setAllSelectedUnitTypes(updatedFlag);
    setUnitTypes(prevUnitTypes =>
      prevUnitTypes.map(unitType => ({ ...unitType, checked: updatedFlag })),
    );
  };

  return {
    unitTypes,
    setUnitTypes,
    handleUnitTypeCheck,
    selectAllUnitTypes,
    allSelectedUnitTypes,
  };
};

const useGetClients = projectId => {
  const [clientList, setClientList] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  useEffect(() => {
    getActiveClientsByProject(
      projectId,
      '',
      ({ data: { result } }) => {
        setClientList(
          [...result].map(({ id_Cliente: clientId, ...ele }) => ({ clientId, ...ele })),
        );
      },
      err => {
        console.error(err);
      },
    );
  }, [projectId]);
  const handleCheck = id => {
    const updateClientList = [...clientList].map(({ clientId, checked = false, ...ele }) => {
      return { ...ele, checked: clientId === id ? !checked : checked, clientId };
    });
    setClientList([...updateClientList]);
  };

  const selectAllClients = () => {
    const updatedFlag = !allSelected;
    const updateClientList = [...clientList].map(element => ({ ...element, checked: updatedFlag }));
    setClientList([...updateClientList]);
    setAllSelected(updatedFlag);
  };

  return { clientList, setClientList, handleCheck, selectAllClients, allSelected };
};

const useHandleDialog = fileOptions => {
  const [openDialog, setOpenDialog] = useState(false);
  const { uploadType } = fileOptions;
  const toogleDialog = () => {
    setOpenDialog(!openDialog);
  };
  useEffect(() => {
    if (uploadType !== '' && dialogOption.includes(uploadType)) {
      setOpenDialog(true);
    }
  }, [uploadType]);
  return { openDialog, toogleDialog };
};

const useHandleFiles = () => {
  const [file, setFileValue] = useState();
  const handleFileChange = ({ target: { files } }, name) => {
    setFileValue({ files, name });
  };
  const cleanFile = () => {
    setFileValue(undefined);
  };
  return { file, handleFileChange, cleanFile };
};

 
const MultipleUpload = ({
  projectName,
  idProject,
  onSubmit,
  documentList = [],
  listDocuments = [],
  deleteCallback,
}) => {

  
  const [fileOptions, setFileOptions] = useState(
    { 
      fileType: '', 
      uploadType: '',
    }
  ); 

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setConfiguration((prevConfig) => ({
      ...prevConfig,
      visualizar: {
        ...prevConfig.visualizar,
        [name]: checked,
      },
    }));
  };

  const { file, handleFileChange, cleanFile } = useHandleFiles();
  const [docTypes, setDocTypes] = useState([]);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [configuration, setConfiguration] = useState();
  const [departmentData, setDepartmentData] = useState([]);

  useEffect(() => {
    listDocumentTypes(
      (response) => {
        const filtered = response.data
          .filter(doc => doc.manualporejecutivo === 1)
          .sort((a, b) => a.id - b.id); 
        const formattedOptions = filtered.map((doc) => ({
          value: doc.tipodocumento,
          label: `${doc.id} - ${doc.tipodocumento}`,
        }));
        setDocTypeOptions(formattedOptions);
        setDocTypes(filtered);
      },
      (error) => console.error(error)
    );
    listDepartmentData(
      idProject,
      (response) => {
        setDepartmentData(response.data);
      }
    )
  }, []);




  const handleSelectUpdate = useCallback(
    ({ target: { value, name } }) => {
      if (name === 'fileType') {
        setFileOptions({ fileType: '', uploadType: '' });
        setConfiguration([]);
  
        const filteredConfiguration = docTypes.filter(doc => doc.tipodocumento === value);
  
        if (filteredConfiguration.length > 0) {
          const formattedConfiguration = transformConfiguration(filteredConfiguration, configuration);
          setConfiguration(formattedConfiguration);  
        }
      }
      setFileOptions({ ...fileOptions, [name]: value });

      if (value === 'POR CLIENTE') {
        setConfiguration((prevConfig) => ({
          ...prevConfig,
          visualizar: {
            ...prevConfig.visualizar,
            'EN ADICIONALES': false,
            'EN COMUNIDADES': false,
          },
        }));
      }

      if(value === 'POR TIPO DE UNIDAD'){
        setConfiguration((prevConfig) => ({
          ...prevConfig,
          visualizar: {
            ...prevConfig.visualizar,
            'EN COMUNIDADES': false,
          },
        }));
      }

    },
    [setFileOptions, fileOptions, docTypes, configuration],
  );

  const { openDialog, toogleDialog } = useHandleDialog(fileOptions);

  const {
    unitTypes,
    handleUnitTypeCheck,
    selectAllUnitTypes,
    allSelectedUnitTypes,
  } = useGetUnitTypes(idProject);
  
  const { clientList, setClientList, handleCheck, selectAllClients, allSelected } = useGetClients(
    idProject,
  );

  const handleSubmit = async () => {

    const { name, files } = file;

    const resp = validarArchivo(files, listDocuments);

    if (fileOptions.uploadType === 'POR TIPO DE UNIDAD') {
      const promises = unitTypes
        .filter(({ checked }) => checked)
        .map(unitType => {
          return new Promise((resolve, reject) => {
            getClientsByUnitType(
              idProject,
              unitType.modelo,
              ({ data }) => {
                resolve(data.data.map(item => item.cliente));
              },
              reject,
            );
          });
        });



      Promise.all(promises)
        .then(results => {
          const clientIds = results.flat();
          if (onSubmit && clientIds.length > 0) {
            onSubmit(name, files, fileOptions.uploadType, configuration.visualizar, clientIds, resp);
            setFileOptions({ fileType: '', uploadType: ''});
            cleanFile();
            setClientList(clientList.map(client => ({ ...client, checked: false })));
          }
        })
        .catch(error => {
          console.error('Error al obtener clientes por tipo de unidad', error);
        });
    } else {
      const formatClientList = clientList
        .filter(({ checked = false }) => checked)
        .map(({ clientId }) => clientId);
      if (onSubmit) {
        onSubmit(name, files, fileOptions.uploadType, configuration.visualizar,  [...formatClientList], resp);
        setFileOptions({ fileType: '', uploadType: '' });
        cleanFile();
        setClientList(clientList.map(client => ({ ...client, checked: false })));
      }
    }
  };

  const permissions = useHasPermission(sessionStorage.getItem('filial'));

  const [selectedType, setSelectedType] = useState("");


  const groupedDocuments = listDocuments.reduce((acc, doc) => {
    if (!acc[doc.documentType]) {
      acc[doc.documentType] = [];
    }
    acc[doc.documentType].push(doc);
    return acc;
  }, {});

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUnitType, setSelectedUnitType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("")

  const handleOpenInfo = (event, idModelo) => {
    setAnchorEl(event.currentTarget);
    setSelectedUnitType(idModelo);
  }

  const handleCloseInfo = () => {
    setAnchorEl(null);
    setSelectedUnitType(null);
  };


  const open = Boolean(anchorEl);


  const [filteredModel, setFilteredModel] = useState('');

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      
      const searchNumber = searchQuery.match(/\d+/)?.[0]; 
      
      if (!searchNumber) {
        setFilteredModel('Debe ingresar el número del modelo');
        return;
      }
  
      const found = departmentData.find((dept) =>
        dept.nombrepropiedad.match(/\d+/)?.[0] === searchNumber
      );
  
      setFilteredModel(found ? `El Dpto. está en el modelo ${found.idmodelo}` : 'No se encontró un modelo');


    }
  }; 

  


  return (
    <Fragment>
      <div className="MultipleUpload">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container spacing={0} alignContent="space-around">
              <Grid item xs={12}>
                <div className="MultipleUpload__title">
                  {!file ? (
                    <Typography variant="subtitle2">ADJUNTAR DOCUMENTO :</Typography>
                  ) : (
                    <MultipleUploadItem
                      name={file.name}
                      to={fileOptions.uploadType}
                      onDelete={cleanFile}
                    />
                  )}
                </div>
              </Grid>

              {/* Seleccionar tipo de configuration */}
              <Grid item xs={12} sm={12}>
                <div className="MultipleUpload__input">
                  <SelectInput
                    name="fileType"
                    options={docTypeOptions}
                    value={fileOptions.fileType}
                    label="Elegir tipo de documento"
                    variant="outlined"
                    onChange={handleSelectUpdate}
                  />
                </div>
              </Grid>

            <Grid xs={12} sm={12}>
              <div
                className="SelectedConfiguration"
                style={{ margin: "1rem", borderRadius: "5px", padding: "1rem" }}
              >
                {configuration ? (
                  <Grid xs={12} sm={12} container spacing={2} className="ConfigurationItem">

                    {/* Opciones de envio de documento */}
                    <Grid item xs={12}>
                      <div className="MultipleUpload__input">
                        <SelectInput
                          name="uploadType"
                          options={configuration.cargar.filter((item) => item.dialog)}
                          value={fileOptions.uploadType}
                          label="TIPO DE ENVIO"
                          disabled={fileOptions.fileType == ''}
                          variant="outlined"
                          onChange={handleSelectUpdate}
                        />
                      </div>
                    </Grid>
                    
                    {/* Opciones de visualizacion */}
                    <Grid item xs={12}>
                      <div className="MultipleUpload__input">
                       <Grid>
                        <FormControlLabel
                            control={<Checkbox checked={configuration.visualizar['EN PROPIETARIOS']} color="primary"/>}
                            onChange={handleCheckboxChange}
                            name="EN PROPIETARIOS"
                            label="VER EN PROPIETARIOS"
                          />
                       </Grid>
                       <Grid>
                       <FormControlLabel
                          control={<Checkbox 
                          checked={configuration.visualizar['EN ADICIONALES']} color="primary" />}
                          disabled={fileOptions.uploadType === 'POR CLIENTE'}
                          onChange={handleCheckboxChange}
                          name="EN ADICIONALES"
                          label="VER EN ADICIONALES"
                        />
                       </Grid>
                       <Grid>
                       <FormControlLabel
                          control={ <Checkbox 
                          checked={configuration.visualizar['EN COMUNIDADES']} color="primary" /> }
                          disabled={fileOptions.uploadType === 'POR TIPO DE UNIDAD' || fileOptions.uploadType === 'POR CLIENTE'}
                          onChange={handleCheckboxChange}
                          name="EN COMUNIDADES"
                          label="VER EN COMUNIDADES"
                        />
                       </Grid>
                      </div>
                    </Grid>
                    
                    {/* Múltiples Documentos */}
                    <Grid item xs={12} container spacing={4} alignItems="center">
                      <Grid item xs={8}>
                        <Typography sx={{ paddingLeft: 40 }}>Múltiples documentos:</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography sx={{ paddingLeft: 2 }}>
                          {configuration.multiplesDocumentos ? "Sí" : "No"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography variant="body2" sx={{ paddingLeft: 2 }}>
                    No hay configuración seleccionada.
                  </Typography>
                )}
              </div>
            </Grid>

              <Grid item xs={12}>
                <div className="MultipleUpload__submitButton">
                  {!file ? (
                    <Fragment>
                      <FileUploadInput
                        onChange={event => handleFileChange(event, fileOptions.fileType)}
                        disabled={fileOptions.uploadType === '' }
                        // multipleFile={configuration && configuration["Múltiples documentos"] !== undefined ? configuration["Múltiples documentos"] : false}
                        multipleFile={true}
                        name="multipleFileUpload"
                        buttonLabel="CARGAR DOCUMENTO"
                      />
                    </Fragment>
                  ) : (
                    <Button 
                      onClick={handleSubmit} style={{ width: '60%' }}>
                      enviar
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      { listDocuments && listDocuments.length ? (
      <Card className="MultipleUploadList" style={{ padding: "20px", borderRadius: "10px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">DOCUMENTOS ENVIADOS:</Typography>
            </Grid>
             <Grid item xs={12} style={{margin:"1rem"}}>
                  <Select
                    fullWidth
                    value={selectedType}
                    displayEmpty
                    onChange={(e) => setSelectedType(e.target.value)}
                    
                  >
                    <MenuItem value="" disabled>Selecciona un tipo de documento</MenuItem>
                    {Object.keys(groupedDocuments).map((documentType) => (
                      <MenuItem key={documentType} value={documentType}>
                        {documentType.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                  <div style={{ paddingLeft: "1rem" }}> 
                    {selectedType && Array.isArray(groupedDocuments[selectedType]) && groupedDocuments[selectedType].length > 0 && (
                      <ul className="MultipleUploadList__list" style={{ marginTop: "15px", padding: "10px", paddingLeft: "1rem", backgroundColor: "#fff", borderRadius: "5px", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)" }}>
                        {groupedDocuments[selectedType].map(({ destinatario: to, etag: id, name, color }) => (
                          <MultipleUploadItem
                            key={id}
                            name={name}
                            to={to}
                            onDelete={() => {
                              setSelectedType("");
                              deleteCallback(id);
                            }}
                            
                            id={id}
                            canDelete={!!permissions['Eliminar Documento Subido']}
                            color={color}
                            onClick={() => {
                              openDocOnNewTab(`${process.env.REACT_APP_URL_BLOBSTORAGE}/${name}`);
                            }}
                          />
                        ))}
                      </ul>
                    )}
                  </div>
              </Grid>
          </Grid>
        </CardContent>
      </Card>
      )
        : (undefined)}
      <Dialog
        title={projectName}
        open={openDialog}
        actions={
          <div className="MultipleUpload__dialog__action">
            <Button variant="contained" onClick={toogleDialog}>
              aceptar
            </Button>
          </div>
        }
      >
        <div className="MultipleUpload__dialog">
          <DeleteButton
            label={false}
            style={{ position: 'absolute', top: '20px', right: '20px' }}
            onClick={toogleDialog}
          />
          { clientList && fileOptions.uploadType === 'POR CLIENTE' && clientList.length > 0 ? (
            <FormControl component="fieldset">
              <FormLabel component="legend">SELECCIONE EL/LOS CLIENTES </FormLabel>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value=""
                  control={<Checkbox color="primary" checked={allSelected} />}
                  label="Seleccionar todos"
                  onClick={() => selectAllClients(allSelected)}
                  labelPlacement="end"
                />
                {clientList.map(
                  ({
                    clientId,
                    nombres,
                    apellidos,
                    metros_Cuadrados: metrosCuadrados,
                    checked = false,
                  }) => (
                    <FormControlLabel
                      value={clientId}
                      control={<Checkbox color="primary" checked={checked} />}
                      label={`${nombres} ${apellidos} (Dpto. - ${metrosCuadrados}) `}
                      onChange={() => handleCheck(clientId)}
                      labelPlacement="end"
                      key={clientId}
                    />
                  ),
                )}
              </FormGroup>
            </FormControl>
          ) : null}
          { unitTypes && unitTypes.length > 0 && fileOptions.uploadType === 'POR TIPO DE UNIDAD' && (
            <FormControl component="fieldset">
              <FormLabel component="legend">SELECCIONE EL/LOS TIPOS DE UNIDAD</FormLabel>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                <TextField
                  label="Buscar departamento"
                  variant="outlined"
                  value={searchQuery}
                  size="small"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={handleKeyPress}
                  style={{ marginBottom: "4px", maxWidth: "200px", maxHeight: "50px" }}
                />
                {filteredModel !== null && (
                  <span style={{ fontSize: "12px", color: "gray", paddingLeft: "1rem" }}>
                  {filteredModel}
                  </span>
                )}
              </div>
              <FormGroup 
                aria-label="position" 
                sx={{ 
                  display: "flex", 
                  flexDirection: "column", 
                  gap: 2,
                  width: "100%", 
                  padding: 2,
                  maxWidth: 600,
                }}
              >
                <FormControlLabel
                  value=""
                  control={
                    <Checkbox
                      color="primary"
                      checked={allSelectedUnitTypes}
                      onChange={() => selectAllUnitTypes()}
                    />
                  }
                  label="Seleccionar todos"
                  labelPlacement="end"
                />
                <Box 
                  sx={{
                    display: "flex",
                    flexWrap: "wrap", 
                    gap: 2, 
                  }}
                >
                  {unitTypes.map(unitType => (
                    <Box 
                      key={unitType.idModelo} 
                      sx={{
                        display: "flex", 
                        alignItems: "center", 
                        flexBasis: "48%", 
                        marginBottom: 2,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={unitType.checked}
                            onChange={() => handleUnitTypeCheck(unitType.idModelo)}
                          />
                        }
                        label={`${unitType.modelo} (${unitType.idModelo})`}
                      />
                      <IconButton size="small" onClick={(e) => handleOpenInfo(e, unitType.idModelo)}>
                        <Info color="primary" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </FormGroup>

              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseInfo}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                sx={{
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    margin: "10px",
                    color: "#1976d2", 
                  }}
                >
                  Departamentos
                </Typography>

                <Box 
                   sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: 5,
                    margin: 2,
                    marginTop: 10,
                    px: 1,
                    maxHeight: 200,
                    maxWidth: 320,
                    overflowY: "auto",
                    backgroundColor: "#fff", 
                    borderRadius: 8, 
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", 
                    "&::-webkit-scrollbar": { width: "5px" },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#bbb",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#999" },
                  }}
                >
                  {departmentData
                    .filter((dept) => dept.idmodelo === selectedUnitType)
                    .map((dept) => (
                      <Box 
                        key={dept.idpropiedad}
                          sx={{
                            backgroundColor: "#f5f5f5",
                            borderRadius: 2,
                            p: 2,
                            textAlign: "center",
                            fontSize: 14,
                            fontWeight: "bold",
                            flexBasis: "120px",
                            flexGrow: 1,
                            maxWidth: "120px",
                            transition: "transform 0.2s ease, box-shadow 0.2s ease",
                            "&:hover": {
                              transform: "scale(1.05)",
                              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)", 
                            },
                          }}
                        >
                        <Typography variant="body2" sx={{ fontWeight: "bold", color: "#333" }}>
                          {dept.nombrepropiedad}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {dept.programa}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Popover>
            </FormControl>
          )}
        </div>
      </Dialog>
    </Fragment>
  );
};

MultipleUpload.propTypes = {
  projectName: Proptypes.string,
  idProject: Proptypes.string,
  onSubmit: Proptypes.func,
  documentList: Proptypes.array,
  deleteCallback: Proptypes.func,
};

export default MultipleUpload;
