import React from 'react';
import IconPropTypes from '../IconPropTypes';

const ImageIcon = ({ className, color = '#FF6900', secondaryColor = '#FFFFFF', onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    onClick={onClick}
  >
    <g fill="none" fillRule="evenodd">
      <rect width="30" height="30" fill={color} rx="2" />
      <g fill={secondaryColor} transform="translate(5 5)">
        <circle cx="10" cy="10" r="7" stroke={secondaryColor} strokeWidth="2" fill="none" />
        <circle cx="10" cy="10" r="3" fill={secondaryColor} />
      </g>
      <circle cx="10" cy="10" r="1" fill={secondaryColor} />
    </g>
  </svg>
);

ImageIcon.propTypes = IconPropTypes;

export default ImageIcon;
