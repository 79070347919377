/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, memo, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import DocumentItem from './DocumentItem';
import DocumentItemPromise from './DocumentItemPromise';
import Button from '../ButtonTypes';
import FileUploadInput from '../FileUploadInput';
import TextFields from '../TextFields';
import { empresas } from '../../constants/filialesNames';
import Dialog from '../Dialog';
import DeleteButton from '../DeleteButton';
import { useHasPermission } from '../../customHooks/usePermissions';
import { colorConfig } from '../../constants/colors';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Accordion } from '@material-ui/core';

import './DocumentList.scss';

const useSectedFile = uploadCallback => {
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState('');
  const handleUploadFile = (files, fileType) => {
    setFileName(files[0].name); 
    uploadCallback(files[0], fileType);
  };
  return { selectedFile, setSelectedFile, fileName, handleUploadFile };
};

const DocumentListExec = ({
  subsidiary,
  fileList,
  uploadCallback,
  deleteCallback,
  onPropertyChecked,
  buttonDel = false,
  onlyList = false,
  proyecto
}) => {
  const { handleUploadFile } = useSectedFile(uploadCallback);
  const [openDialog, setOpenDialog] = useState(false);
  const [disableButtonDialog, setDisableButtonDialog] = useState(true);
  const [documentLabel, setDocumentLabel] = useState('');
  const [documentFileEvent, setDocumentFileEvent] = useState([]);
  const [fileKey, setFileKey] = useState(Date.now());

  function getFileType(nombreArchivo) {
    const imagenExtensiones = ['png', 'jpg', 'jpeg'];
    const videoExtensiones = ['mp4'];
  
    if (!nombreArchivo || typeof nombreArchivo !== 'string') {
      return 'documento'; 
    }
  
    const extension = nombreArchivo.split('.').pop()?.toLowerCase();
    

    if (extension) {
      if (imagenExtensiones.includes(extension)) {
        return 'imagen';
      } else if (videoExtensiones.includes(extension)) {
        return 'video';
      } else {
        return 'documento';
      }
    }
  
    return 'documento'; // Si no tiene extensión válida
  }

  const toogleDialog = () => {
    setOpenDialog(!openDialog);
    setFileKey(Date.now());
  };

  const openModalDocumentName = event => {
    setDocumentFileEvent(event.target.files);
    toogleDialog();
  };

  const handleChange = text => {
    if (text) {
      setDocumentLabel(text);
      setDisableButtonDialog(false);
    } else {
      setDisableButtonDialog(true);
    }
  };

  const handleUploadDocument = () => {
    handleUploadFile(documentFileEvent, documentLabel);
    toogleDialog();
    setDocumentLabel('');
  };

  const handleDelete = file => {
    deleteCallback(file);
  };

  const handleUpload = () => {
    handleUploadDocument();
  };
  const permissions = useHasPermission(sessionStorage.getItem('filial'), null); // TODO: change the null to the Units state

  const [openGroups, setOpenGroups] = useState([]);
  const toggleGroup = (groupId) => {
    setOpenGroups(prevState =>
      prevState.includes(groupId)
        ? prevState.filter(id => id !== groupId)  // Cerrar si ya está abierto
        : [...prevState, groupId]  // Abrir si no está abierto
    );
  };


  console.log(fileList);

  return (
    <Fragment>
      <Dialog
        className="DocumentList__dialogModal"
        open={openDialog}
        title="EDITAR NOMBRE DEL DOCUMENTO"
        stylePaper={{
          style: {
            padding: '4px 100px 20px 0px',
          },
        }}
        actions={
          <div className="DocumentList__dialogModal__actions">
            <Button
              className="DocumentList__dialogModal__actions__button"
              variant="contained"
              onClick={() => handleUpload()}
              disabled={disableButtonDialog}
            >
              CARGAR
            </Button>
          </div>
        }
      >
        <div className="DocumentList__dialogModal__content">
          <DeleteButton
            label={false}
            style={{ position: 'absolute', top: '20px', right: '20px' }}
            onClick={toogleDialog}
          />
          <TextFields
            name="document_name"
            label="NOMBRE DOCUMENTO:"
            onChange={e => handleChange(e.target.value)}
          />
        </div>
      </Dialog>

      <div className={`DocumentList DocumentList--${subsidiary}`}>
       {fileList && fileList.length > 0 && fileList.map((group) => (
          <Accordion key={group._id} className="DocumentList__group">
            <AccordionSummary
              expandIcon={<span className="DocumentList__arrow">▼</span>}
              className="DocumentList__title"
            >
              {group._id}
            </AccordionSummary>
            <AccordionDetails className="DocumentList__documents">
              {group.documentos.map(({ name, label, link, lastModified, etag, original_name }) => (
                <div className="DocumentList__details" key={etag}>
                  <DocumentItem
                    subsidiary={subsidiary}
                    name={name}
                    lastModified={lastModified}
                    label={label.replace(/_/g, ' ')}
                    deleteCallback={() => handleDelete(name)}
                    link={link}
                    buttonDel={buttonDel}
                    onlyList={onlyList}
                    extension={getFileType(original_name)}
                    proyecto={proyecto}
                    tipoDocumento={group._id}
                  />
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}

        {subsidiary === empresas && buttonDel && (
          <div className="DocumentList__buttons">
            <FileUploadInput
              // disabled={!permissions['Subir Documento en Negocio']}
              onChange={e => openModalDocumentName(e)}
              buttonLabel="SUBIR DOCUMENTO"
              className="DocumentList__buttons__uploadButton"
              fileKey={fileKey}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

DocumentListExec.propTypes = {
  subsidiary: PropTypes.string,
  fileList: PropTypes.array,
  uploadCallback: PropTypes.func,
  idPropiedad: PropTypes.number,
  deleteCallback: PropTypes.func,
  listFiles: PropTypes.array,
  deptos: PropTypes.array,
  proyecto: PropTypes.string
};

export default memo(DocumentListExec);
