/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../ButtonTypes';

const defaultAcceptedFileType = 'application/pdf,video/mp4,image/jpg,image/jpeg,image/png';



const FileUploadInput = ({
  multipleFile = false,
  disabled = false,
  onChange,
  accept = defaultAcceptedFileType,
  style = {},
  buttonLabel = 'SUBIR DOCUMENTO',
  className,
  fileKey,
  name,
}) => {
  return (
    <Fragment>
      <input
        accept={accept}
        style={{ display: 'none' }}
        id={`${name}-raised-button-file`}
        disabled={disabled}
        type="file"
        multiple={multipleFile}
        key={fileKey}
        onChange={onChange}
        name={name}
      />
      <label htmlFor={`${name}-raised-button-file`}>
        <Button
          variant="contained"
          size="small"
          style={style}
          component="span"
          className={className}
          disabled={disabled}
        >
          {buttonLabel}
        </Button>
      </label>
    </Fragment>
  );
};

FileUploadInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  accept: PropTypes.string,
  style: PropTypes.object,
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  fileKey: PropTypes.number,
};

export default FileUploadInput;
